<template>
  <table-view :total='total' :query-info.sync='queryInfo' @reload-table='renderTable'>
    <template #header>
      <div class='header-button'>
        <el-button v-permission="['basic:grade:edit']" type='primary' size='small' @click="showDialog()">添加学年</el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data='tableData' style='width: 100%' border stripe>
      <el-table-column type='index' label='ID' width='50' />
      <el-table-column prop='gradeName' label='学年' width='200' />
      <el-table-column prop='beginDate' label='开始时间' width='200' />
      <el-table-column prop='endDate' label='结束时间' width='200' />
      <el-table-column prop='payEndDate' label='缴费截止时间' width='200' />
      <el-table-column prop='remarks' label='备注' />
      <el-table-column label='操作' width='150' fixed='right'>
        <template v-slot='{ row }'>
          <el-button v-permission="['basic:grade:edit']" type='primary' size='mini' @click="showDialog(row.id)">编辑
          </el-button>
          <el-button v-permission="['basic:grade:del']" size='mini' type='danger'
            @click='del(deleteGrade, row.id, `${row.gradeName}学年`)'>删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <grade-dialog ref='dialogRef' :visible.sync='visibleDialog' v-if='visibleDialog' @on-close='renderTable' />
  </table-view>
</template>

<script>
import { getGradeList, deleteGrade } from '@/api/grade'
import gradeDialog from './dialog/gradeDialog.vue'
import tableView from '@/vue/mixins/table-view'

export default {
  name: 'grade',
  components: {
    gradeDialog
  },
  mixins: [tableView],
  data() {
    return {
    }
  },
  mounted() {
    this.renderTable()
  },
  methods: {
    deleteGrade, // 删除api
    async renderTable(pageNum) {
      await this.getTableData(getGradeList, pageNum)
    }
  }
}
</script>
