<template>
  <el-dialog
    class="grade-dialog"
    :title="`${preTitle}学年`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="540px"
    :before-close="cancel"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="学年" prop="gradeName">
        <el-input disabled v-model.trim="formData.gradeName" />
      </el-form-item>
      <el-form-item label="开始/结束" prop="beginDate">
        <el-col :span="11">
          <el-date-picker
            type="date"
            placeholder="开始时间"
            v-model="formData.beginDate"
            style="width: 100%"
            value-format="yyyy-MM-dd"/>
        </el-col>
        <el-col class="line" style="text-align: center" :span="2">-</el-col>
        <el-col :span="11">
          <el-date-picker
            type="date"
            placeholder="结束时间"
            v-model="formData.endDate"
            style="width: 100%"
            value-format="yyyy-MM-dd"/>
        </el-col>
      </el-form-item>
      <el-form-item label="缴费截止时间" prop="payEndDate">
        <el-date-picker
          type="date"
          placeholder="结束时间"
          v-model="formData.payEndDate"
          :picker-options="pickerOptions"
          style="width: 100%"
          value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('学年',addGrade)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addGrade, getGradeById } from '@/api/grade'
import dialog from '@/vue/mixins/dialog'

export default {
  name: 'gradeDialog',
  mixins: [dialog],
  data() {
    return {
      formData: {
        id: '',
        beginDate: '', // 开始时间
        endDate: '', // 结束时间
        gradeName: '', // 学年
        payEndDate: '',
        remarks: ''
      },
      rules: {},
      pickerOptions: {
        disabledDate: (time) => {
          const timesValue = this.$moment(time).valueOf()
          const _endTime = this.$moment(this.formData.endDate).valueOf()
          return timesValue > _endTime
        }
      }
    }
  },
  watch: {
    'formData.beginDate': function(beginDate) {
      this.formData.gradeName = beginDate.slice(0, beginDate.indexOf('-')) + '-' + this.formData.endDate.slice(0, this.formData.endDate.indexOf('-'))
    },
    'formData.endDate': function(endDate) {
      this.formData.gradeName = this.formData.beginDate.slice(0, this.formData.beginDate.indexOf('-')) + '-' + endDate.slice(0, endDate.indexOf('-'))
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getGradeById)
      } else {
        this.setInitData()
      }
    })
  },
  methods: {
    addGrade // 保存 api
  }
}
</script>
